import React, { useState,useEffect } from 'react';
import { Account } from '../interfaces';
import { useTranslation } from 'react-i18next';



const MakeTransfer: React.FC = () => {
  const [sourceAccount, setSourceAccount] = useState<number>();
  const [receiverEmail, setReceiverEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [accounts,setAccounts] = useState<Account[]>([]);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();


  

  const fetchUserAccounts = async () => {
    const token = localStorage.getItem('token'); // Get the stored token
    const response = await fetch('http://localhost:4000/api/accounts', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  
    if (response.ok) {
      const accounts = await response.json();
      console.log(accounts)
      setAccounts(accounts);
      setSourceAccount(accounts.find((acc:Account)=>  acc.currency==="USD")?.accountid)
    } else {
      throw new Error('Unable to fetch accounts');
    }
  };

  useEffect(()=>{
    fetchUserAccounts() 
  },[])

  const performTransfer = async () => {
    if(sourceAccount && receiverEmail && amount){
      const token = localStorage.getItem('token'); // Get the stored token from local storage
    const transferData = {
      fromAccountId:sourceAccount, // ID of sender's account
      recipientEmail:receiverEmail, // Recipient's email
      amount, // Amount to transfer
    };
  
    try {
      const response = await fetch('http://localhost:4000/api/transfer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Authorization header with the token
        },
        body: JSON.stringify(transferData), // Stringify the transfer data to send in the request body
      });
  
      if (response.ok) {
        const result = await response.json();
        fetchUserAccounts();
        setAmount('');
        setReceiverEmail('');
        setInfoMessage(result.message)
        setTimeout(()=>setInfoMessage(''),3000)
      } else {
        // Handle HTTP errors
        setErrorMessage("User with such email was't found");
        setTimeout(()=>setErrorMessage(''),3000)
      }
    } catch (error) {
      // Handle network errors
      alert("Network error")
    }
    }
  };

  const handleTransferClick = () => {
    const isConfirmed = window.confirm('Are you sure you want to create this transfer?');
    if (isConfirmed) {
      // Proceed with the transfer
      performTransfer();
    }
  };
  
  

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">{t('create-transfer-header')}</h2>
        {infoMessage && <div className="text-green-500">{infoMessage}</div>}
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        <div className="mb-4">
          <label htmlFor="sourceAccount" className="block text-sm font-medium text-gray-700">
            {t('source-account')}
          </label>
          <select
            id="sourceAccount"
            value={sourceAccount}
            onChange={(e) => setSourceAccount(Number(e.target.value))}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-2 border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {accounts.map((account)=>{
              if (account.currency==='USD'){
                return <option key={account.accountid} value={account.accountid}>{account.currency} {account.accountid} | {account.balance}{account.currency} {t('available')} </option>
              }
            })}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="receiverEmail" className="block text-sm font-medium text-gray-700">
            {t('receiver-email')}
          </label>
          <input
            type="email"
            id="receiverEmail"
            value={receiverEmail}
            onChange={(e) => setReceiverEmail(e.target.value)}
            className="mt-1 p-2 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
            {t('amount')}
          </label>
          <input
            type="text"
            inputMode="numeric"
            id="amount"
            value={amount}
            onChange={(e) => {
              // Update the amount, ensuring it doesn't exceed the source account balance
              const inputAmount = parseFloat(e.target.value);
              //@ts-ignore
              if (inputAmount <= (accounts.find((acc)=>  acc.accountid==sourceAccount)?.balance) && inputAmount>=0 || isNaN(inputAmount)) {
                setAmount(e.target.value);
              }
            }}
            className="mt-1 p-2 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <button
          onClick={handleTransferClick}
          className="bg-blue-600 text-white py-2 px-4 rounded w-full hover:bg-opacity-80"
        >
          {t('send-transfer')}
        </button>
      </div>
    </div>
  );
};

export default MakeTransfer;
