// SignUp.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { use } from 'i18next';

const SignUp: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate(); // Initialize useNavigate

  const {t} = useTranslation();

  const validateForm = () => {
    let valid = true;
    let newErrors = {
      name: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    // Name validations
    if (!formData.name) {
      newErrors.name = t('name-is-required');
      valid = false;
    }

    // Last Name validations
    if (!formData.lastName) {
      newErrors.lastName = t('last-name-is-required');
      valid = false;
    }

    // Email validations
    if (!formData.email) {
      newErrors.email = t('email-is-required');
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('email-is-invalid');
      valid = false;
    }

    // Password validations
    if (!formData.password) {
      newErrors.password = t('password-is-required');
      valid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = t('password-must-be-at-least-6-characters');
      valid = false;
    }

    // Confirm Password validations
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = t('confirm-password-is-required');
      valid = false;
    } else if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = t('confirm-password-is-required');
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };


  const handleSignUp = async () => {
    if (validateForm()) {
      try {
        const response = await fetch('http://localhost:4000/api/signUp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({email:formData.email,password:formData.password,firstName:formData.name,lastName:formData.lastName}),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('Sign up successful:', data);
          navigate('/signin');
        } else {
          // If the response is not ok and not JSON, handle as text
          const errorMessage = await response.text();
          console.error('Sign up error:', errorMessage);
          // Set error message in state here
          setErrorMessage(errorMessage);
          setTimeout(()=>setErrorMessage(''),3000)
        }
      } catch (error) {
        console.error('Sign up failed:', error);
        // Handle network or parsing errors here
        // Set error message in state here
      }
    }
  };
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">{t('registration')}</h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              {t('name')}
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder={t('enter-your-name')}
              className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              {t('last-name')}
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder={t('enter-your-last-name')}
              className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            {errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName}</p>}
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {t('email')}
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder={t('enter-your-email')}
            className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
          {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            {t('password')}
          </label>
          <input
            data-testid="password-input"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder={t('enter-your-password')}
            className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
          {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
        </div>
        <div className="mb-6">
          <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
            {t('confirm-password')}
          </label>
          <input
            data-testid="confirm-password-input"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder={t('enter-your-confirm-password')}
            className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
          {errors.confirmPassword && <p className="text-red-500 text-xs italic">{errors.confirmPassword}</p>}
        </div>
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        <button
          data-testid="submit-button"
          onClick={handleSignUp}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          {t('sign-up')}
        </button>
        <button  className='pt-2 text-gray-500' onClick={()=>navigate('/signin')}>{t('authorization')}</button>

      </div>
      
    </div>
  );
};

export default SignUp;
