import { useTranslation } from 'react-i18next';

interface AccountProps {
    title: string;
    currency: string;
    balance: number;
  }

const AccountCard: React.FC<AccountProps> = ({ title, currency, balance }) => {

  const { t } = useTranslation();

    return (
      <div className="bg-white p-4 rounded-lg shadow-md mb-4" data-testid="account-card">
        <h3 className="font-bold text-lg mb-2">{title}</h3>
        <p className="text-gray-600">{t('currency')}: {currency}</p>
        <p className="text-gray-600">{t('balance')}: {currency} {balance}</p>
        {/* <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Make transfer
        </button> */}
      </div>
    );
  };

  export default AccountCard;