import React, { useEffect, useState } from 'react';
import { Account, ExchangeRate } from '../interfaces';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';

const CurrExchange: React.FC = () => {
  const [sourceAccount, setSourceAccount] = useState<number>();
  const [destAccount, setDestAccount] = useState<number>();
  const [accounts,setAccounts] = useState<Account[]>([]);

  const [rate,setRate]= useState<ExchangeRate>()
  const [amount, setAmount] = useState('');
  const { t } = useTranslation();

  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchUserAccounts = async () => {
    const token = localStorage.getItem('token'); // Get the stored token
    const response = await fetch('http://localhost:4000/api/accounts', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  
    if (response.ok) {
      const accounts = await response.json();
      setAccounts(accounts);
      setSourceAccount(accounts.find((acc:Account)=>  acc.currency==="USD")?.accountid)
    } else {
      // Handle any errors, such as redirecting to login if the response status is 401
      throw new Error('Unable to fetch accounts');
    }
  };

  useEffect(()=>{
    fetchUserAccounts()
  },[])
  
  useEffect(()=>{
    async function getRate() {
      if (sourceAccount&&destAccount){
        const response = await fetch(`http://localhost:4000/api/exchangeRate?baseCurrency=${accounts.find((acc)=>  acc.accountid==sourceAccount)?.currency}&targetCurrency=${accounts.find((acc)=> acc.accountid==destAccount)?.currency}`);
    
      if (response.ok) {
        const rate = await response.json();
        setRate((rate[0]));
      } else {
        // Handle any errors, such as redirecting to login if the response status is 401
        throw new Error('Unable to fetch accounts');
      }

      }
    }
    getRate()
  },[sourceAccount,destAccount])


  const handleExchange = async () => {
    // Construct the body of the request with necessary information
    const exchangeData = {
      fromAccountId: sourceAccount,
      toAccountId: destAccount,
      baseCurrency:accounts.find((acc)=>  acc.accountid==sourceAccount)?.currency,
      targetCurrency:accounts.find((acc)=>  acc.accountid==destAccount)?.currency,
      amount: parseFloat(amount), // Ensure the amount is a number
      // You may also want to send the exchange rate and currency if needed
    };
  
    const token = localStorage.getItem('token'); // Get the stored token
    try {
      const response = await fetch('http://localhost:4000/api/exchangeCurrency', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Attach the JWT token for authorization
        },
        body: JSON.stringify(exchangeData),
      });
  
      if (response.ok) {
        // Handle the successful exchange
        setInfoMessage('Currency exchanged successfully')
        setTimeout(()=>setInfoMessage(''),3000)
        fetchUserAccounts();
        setAmount('');

        console.log('Currency exchanged successfully');
      } else {
        // Handle errors - the server might return an error if, e.g., the balance is insufficient
        const errorData = await response.json();
        console.error('Failed to exchange currency:', errorData.message);
        alert(errorData.message); // Display error message to the user
      }
    } catch (error) {
      // Handle network errors
      console.error('Network error:', error);
      alert('Failed to connect to the server.');
    }
  };

  const handleTransferClick = () => {
    if (!destAccount){
      setErrorMessage("Please select destination account")
      setTimeout(()=>setErrorMessage(''),3000)
    }else if (!amount){
        setErrorMessage("Please enter amount")
        setTimeout(()=>setErrorMessage(''),3000)
    }else{
      const isConfirmed = window.confirm('Are you sure you want to make this exchange?');
        if (isConfirmed) {
          // Proceed with the transfer
          handleExchange();
    }
    }
  }
  

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
     

      <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">{t('currency-exchange')}</h2>
        {infoMessage && <div className="text-green-500">{infoMessage}</div>}
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        <div className="mb-4">
          <label htmlFor="sourceAccount" className="block text-sm font-medium text-gray-700">
            {t('source-account')}
          </label>
          <select
            data-testid="source-account-select"
            id="sourceAccount"
            value={sourceAccount}
            onChange={(e) => setSourceAccount(Number(e.target.value))}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-2 border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {accounts.map((account)=>{
              return <option key={account.accountid} value={account.accountid}>{account.currency} {account.accountid} | {account.balance}{account.currency} {t('available')} </option>
            })}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="destAccount" className="block text-sm font-medium text-gray-700">
            {t('destination-account')}
          </label>
          <select
            data-testid="dest-account-select"
            id="destAccount"
            value={destAccount}
            onChange={(e) => setDestAccount(Number(e.target.value))}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-2 border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value=''>{t('select-destination-account')}</option>
            {accounts.map((account)=>{
              if (account.currency!==accounts.find((acc)=> acc.accountid==sourceAccount)?.currency){
              return <option key={account.accountid} value={account.accountid}>{account.currency} {account.accountid} | {account.balance}{account.currency} available </option>
}})}
            {/* Add more currencies as needed */}
          </select>
        </div>
        {rate
        ?<div className="mb-3" >
          <label htmlFor="amount" className="block text-sm font-medium text-gray-700" data-testid="exchange-rate">
            {t('exchange-rate')}:
          </label>
            <div className="exchRate">
                1 {rate.basecurrency} = {Number(rate.rate)} {rate.targetcurrency}
            </div>
        </div>
        :null}
        <div className="mb-6">
        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
            {t('amount')}
          </label>
          <input
            type="number" // Change input type to number for proper validation
            id="amount"
            value={amount}
            onChange={(e) => {
              // Update the amount, ensuring it doesn't exceed the source account balance
              const inputAmount = parseFloat(e.target.value);
              //@ts-ignore
              if (inputAmount <= (accounts.find((acc)=>  acc.accountid==sourceAccount)?.balance) && inputAmount>=0 || isNaN(inputAmount)) {
                setAmount(e.target.value);
              }
            }}
            placeholder={t('enter-amount')}
            max={accounts.find((acc)=>  acc.accountid==sourceAccount)?.balance} // Set the max attribute to source account balance
            className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>
        <button
          onClick={handleTransferClick}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
           // Disable button if amount is not valid
        >
          {t('confirm')}
        </button>
      </div>
    </div>
  
  );
          }


export default CurrExchange;
