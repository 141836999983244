import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface modalProps{
        isOpen:boolean,
        onClose:(event: React.MouseEvent<HTMLButtonElement>) => void,
        onConfirm:(currency:string)=>void,
}

const NewAccountModal:React.FC<modalProps> = ({ isOpen, onClose, onConfirm }) => {

    const {t} = useTranslation();
    const [currency, setCurrency] = useState('');

    if (!isOpen) return null;

    if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-40 flex ">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold mb-4">{t('create-new-account')}</h3>
        <label htmlFor="currency" className="block mb-2 text-sm font-bold text-gray-700">
          {t('currency')}:
        </label>
        <select
          id="currency"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          className="mb-4 block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">{t('select-currency')}</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="RUB">RUB</option>
          {/* Add more currencies as needed */}
        </select>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded mr-2"
          >
            {t('cancel')}
          </button>
          <button
            onClick={() => onConfirm(currency)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {t('open')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewAccountModal;
