//@ts-nocheck

import React, { useState, useEffect } from 'react';

const AdminPanel = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        fetch('http://localhost:4000/api/admin/users')
            .then(res => res.json())
            .then(data => setUsers(data))
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        if (selectedUser) {
            fetch(`http://localhost:4000/api/admin/user/${selectedUser}/accounts`)
                .then(res => res.json())
                .then(data => setAccounts(data))
                .catch(err => console.error(err));
        }
    }, [selectedUser]);

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleBalanceUpdate = (accountId, newBalance) => {
        fetch(`http://localhost:4000/api/admin/user/${selectedUser}/account/${accountId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newBalance }),
        })
        .then(res => {
            if (res.ok) {
                alert('Balance updated successfully');
            } else {
                alert('Failed to update balance');
            }
        })
        .catch(err => console.error(err));
    };

    return (
        <div className='fixed mt-28'>
            <h2>Admin Panel</h2>
            <select onChange={handleUserChange}>
                <option value="">Select User</option>
                {users.map(user => (
                    <option key={user.userid} value={user.userid}>
                        {user.email}
                    </option>
                ))}
            </select>
            <div>
                {accounts.map(account => (
                    <div  className='mt-2 ml-3' key={account.accountid}>
                        <span  >Account ID: {account.accountid} | Balance: {account.balance} | </span>
                        <button className='ml-3 bg-slate-400 rounded-sm' onClick={() => handleBalanceUpdate(account.accountid, prompt('New balance'))}>
                            Update Balance
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminPanel;
