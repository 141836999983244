import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Main from './pages/Main';
import Accounts from './pages/Accounts'
import MakeTransfer from './pages/MakeTransfer';
import CurrExchange from './pages/CurrExchange';
import { AuthProvider } from './hooks/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import './i18n';
import AdminPanel from './pages/AdminPanel';



const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute><Main/></ProtectedRoute>,
  },
  {
    path:"/signin",
    element:<SignIn/>
  },
  {
    path:"/signup",
    element:<SignUp/>
  },
  {
    path:"/accounts",
    element:<ProtectedRoute><Accounts/></ProtectedRoute>
  },
  {
    path:"/newTransfer",
    element:<ProtectedRoute><MakeTransfer/></ProtectedRoute>
  },
  {
    path:"/exchange",
    element:<ProtectedRoute><CurrExchange/></ProtectedRoute>
  },
  {
    path:"/panel",
    element:<AdminPanel/>
  }
  
  
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AuthProvider>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
</AuthProvider>
  

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
