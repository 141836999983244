import React,{useEffect, useState} from 'react';
import AccountCard from '../components/AccountCard';
import { Account } from '../interfaces';
import NewAccountModal from '../components/CreateAccountModal';
import { useTranslation } from 'react-i18next';




const Accounts: React.FC = () => {
  const [isModal,setIsModal] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);

  const { t } = useTranslation();

  const createAccount = async (currency: string) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('http://localhost:4000/api/openAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ currency }),
      });
  
      if (response.ok) {
        const newAccount = await response.json();
        setAccounts([...accounts, newAccount]); // Update the accounts list
        setIsModal(false); // Close the modal
      } else {
        // Handle errors, e.g., showing an error message to the user
        console.error('Failed to create account');
      }
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };
  


  //get accounts from back
  useEffect(()=>{
    const fetchUserAccounts = async () => {
      const token = localStorage.getItem('token'); // Get the stored token
      const response = await fetch('http://localhost:4000/api/accounts', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    
      if (response.ok) {
        const accounts = await response.json();
        console.log(accounts)
        setAccounts(accounts);
      } else {
        // Handle any errors, such as redirecting to login if the response status is 401
        throw new Error('Unable to fetch accounts');
      }
    };

    fetchUserAccounts()
    
  },[])



  return (
      <div className='flex justify-center items-center h-screen'>
      <div className="w-full max-w-2xl">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">{t('your-accounts')}</h1>
        {accounts.map((account:Account)=>{
          return <AccountCard title={`${t('account')} ${account.accountid}`} key={account.accountid} currency={account.currency} balance={account.balance} />
        })}
        <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 w-full" onClick={()=>setIsModal(true)}>
          {t('create-new-account')}
        </button>
      <NewAccountModal isOpen={isModal} onConfirm={createAccount} onClose={() => setIsModal(false)}/>
      </div>
      </div>
  );
};

export default Accounts;
