// Auth.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import { useTranslation } from 'react-i18next';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  const { isAuth,setIsAuth } = useAuth();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async () => {
    if (validateForm()) {
      try {
        const response = await fetch('http://localhost:4000/api/signIn', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('token', data.token);
          setIsAuth(true);
          navigate('/');
        } else {
          const errorData = await response.json(); // Assuming error response is in JSON
          setErrorMessage(errorData.message || 'Invalid email or password');
          setTimeout(() => setErrorMessage(''), 3000);
        }
      } catch (error) {
        console.error('Login error:', error);
        setErrorMessage('Something went wrong');
        setTimeout(() => setErrorMessage(''), 3000);
      }
    }
  };
  

  


  const validateForm = () => {
    let valid = true;
    if (!email) {
      setErrors(prev => ({ ...prev, email: 'Email is required' }));
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setErrors(prev => ({ ...prev, email: 'Email is invalid' }));
      valid = false;
    }
    if (!password) {
      setErrors(prev => ({ ...prev, password: 'Password is required' }));
      valid = false;
    }

    return valid;
  };

 

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">{t('authorization')}</h2>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {t('email')}
          </label>
          
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
          {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            {t('password')}
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="mt-1 block w-full border-2 border-gray-300 px-3 py-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
          
          {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
        </div>
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        
        <button
          onClick={handleLogin}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          {t('login')}
        </button>
        <button className='pt-2 text-gray-500' onClick={()=>navigate('/signup')}>{t('registration')}</button>

      </div>
    </div>
  );
};

export default SignIn;
