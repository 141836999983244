import React from 'react';
import { useAuth } from '../hooks/AuthContext';
import { useTranslation } from 'react-i18next';

const LanguageToggle = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'en' ? 'ru' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  return (
    <button className='text-yellow-100 text-2xl' onClick={toggleLanguage}>
      <p className='pb-2'>{i18n.language === 'en' ? 'eng' : 'ru'}</p>
    </button>
  );
};


const Header = () => {
    const {t} = useTranslation();
    const { isAuth,setIsAuth } = useAuth();

    return (
        <div className='bg-blue-600 w-[100vw] h-14 fixed top-0 flex flex-wrap justify-between' >
        <div className='h-[100%] content-center'>
            <a href='/'><p className='text-cyan-100 pt-2 pl-3 text-3xl'>{t('online-bank')}</p></a>
        </div>
        

        <div className='pr-4 flex flex-wrap'>

        <LanguageToggle/>

            {isAuth
            ?<a href='/signin'><p onClick={()=>{
                localStorage.removeItem('token')
                setIsAuth(false)}}
                 className='text-cyan-100 pt-2 pl-3 text-2xl'>{t('logout')}</p></a>
            :null}
        </div>
        </div>
    );
};

export default Header;