// AuthContext.tsx
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import Header from '../components/Header';

interface AuthContextType {
  isAuth: boolean;
  isLoading: boolean; // Add a loading state
  setIsAuth: (isAuth: boolean) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuth(true);
    }
    setIsLoading(false); // Set loading to false after checking token
  }, []);

  return (
    <AuthContext.Provider value={{ isAuth, isLoading, setIsAuth }}>
      <Header/>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
