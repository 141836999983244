import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Main: React.FC = () => {

    const { t } = useTranslation();
    const [totalBalance,setTotalBalance] = useState()

    const navigate = useNavigate();
    useEffect(
      ()=>{
      const getTotalBalance = async () => {
        const token = localStorage.getItem('token'); // Get the stored token
        const response = await fetch('http://localhost:4000/api/getTotalBalance', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      
        if (response.ok) {
          const res = await response.json();
          setTotalBalance(res.totalBalanceUSD);
        } else {
          // Handle any errors, such as redirecting to login if the response status is 401
          throw new Error('Unable to fetch accounts');
        }
}
  getTotalBalance()
    },[])


  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-2 text-gray-800">{t('online-bank')}</h2>
        <div className="mb-4">
          <div className="text-gray-700 text-md mb-2">{t('total-balance')}</div>
          <div className="text-gray-900 text-3xl font-bold">${totalBalance}</div>
        </div>
        <div className="flex flex-col">
          <button onClick={()=> navigate('/newTransfer')} className="bg-blue-500 text-white text-sm py-2 px-4 rounded mb-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
             {t('send-money')}
          </button>
          <button onClick={() => navigate('/exchange')} className="bg-blue-500 text-white text-sm py-2 px-4 rounded mb-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
            {t('currency-exchange')}
          </button>
          <button onClick={() => navigate('/accounts')} className="bg-blue-500 text-white text-sm py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
            {t('accounts')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Main;
